.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section {
  color: black;
  background-color: #E2E5E9;
  width:auto;
  height: 100vh;
  box-shadow: '1px 2px 9px #F4AAB9';
}

.navBg {
  background-color: white;
  color: black;
  box-shadow: '1px 2px 8px #F4AAB9';
  margin: '5em';
  padding: '1em';
}

nav a.active{
  text-decoration: underline;
}


#nav{
  color: #000000;
  margin: auto;
}

#basic-nav-dropdown{
  color: #000000;
}


#perfil{
  size: 20px;
  color: #000000;
  font-size:x-large;
  padding: 0 10px;
  align-items: center;
  margin-right: 10px;
  text-align: left;
  justify-content: center;
}


h1{
  color: rgb(5, 5, 5);
  text-shadow: 0px 0px #000000;
  padding: 5%;
}

p{
  color: black;
  text-shadow: 0px 0px #000000;
  padding: 2%;
  display: block;
  text-decoration: none;
}

a{
  color: black;
  text-shadow: 0px 0px #000000;
  padding: 2%;
  display: block;
  text-decoration: none;
}


.list-group{
  font-family: Arial, Helvetica, sans-serif;
}

#ppal{
  background-color: #ADD8FF;
  border: 1px solid #DBDBDB;
  height: 100vh;
  padding: 15px 10px;
  border-radius: 5px;
  height: 100vh;
}



#ppal .container .container{
  border-radius: 5px;
  list-style-type: none;
  width: 40%;
  height:auto;
  justify-content: left;
}

#ppal .container  #detalle{
  background-color: transparent;
  clear:left;
  float:left;
  overflow:hidden;
}


#ppal .container{
  background-color: white;
  border-radius: 5px;
  text-align: left;
  margin-left: 5px;
  margin-right:5px;
  list-style-type: none;
  width: auto;
  padding: 0;
  height:auto;
}


#bt1{
  font-size: medium;
  background-color:#DDD8D8;
  border: 1px solid #dbdbdbe4;
  color: #000000;
}

#bt2{
  background-color: #12E74E;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  border: 0px solid #DBDBDB;
}

.alert{
  justify-content: center;
}

#denuncias{
  color:#000000
}

h1 span{
  border-radius: 5px;
  padding: 1%;
}

#contenedornav{
  background-color: #ffffff;
}
