.divStyle {
    width: 500px;
    background-color:white;
    text-align: center;
    margin: auto;
    border: solid 2px black;
}

#downloadBtn{
    background-color:#DDD8D8;
    border: solid 1px black;
    border-radius: 5px;
}
